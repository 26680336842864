<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <customerLogin></customerLogin>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import Vue from 'vue'
import { loading, showMessage, userInfo } from '../mixins'
import customerLogin from '../components/customers/CustomerLogin.vue'
export default {
  name: 'HomePage',
  components: { customerLogin },
  data: () => ({
    // bus: new Vue()
  }),
  mixins: [loading, showMessage, userInfo],
  methods: {
  },
  mounted () {
  }
}
</script>
<style>

</style>
