<template>
  <v-form v-model="isValidData" ref="validData" enctype="multipart/form-data">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:center;">
            <h3>Configuraciones</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <div>&nbsp;</div>
      <v-card-text>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:left;">
            <v-radio-group v-model="selectedRfc" label="Emisores">
              <v-radio v-for="n in rfcs" :title="n.company" :key="n.id" :label="n.rfc" :value="n.id"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:left;">
            <v-text-field label="Numero de telefono" :rules="[rules.required]" v-model="settingsData.phone" type="test"
              :disabled="isLoading"></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:left;">
            <v-text-field label="Email" :rules="[rules.required]" v-model="settingsData.email" type="text"
              :disabled="isLoading"></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:left;">
            <v-textarea label="Texto libre" :rules="[rules.required]" v-model="settingsData.freeText"
              :disabled="isLoading"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <div>&nbsp;</div>
      <v-card-actions>
        <v-row align="center" class="mx-4">
          <v-col style="text-align:center;">
            <v-btn :loading="isLoading" @click.stop="closeDialog()" color="error" depressed>Cancelar</v-btn>
          </v-col>
          <v-col style="text-align:center;">
            <v-btn :loading="isLoading" @click.stop="saveSettings()" :disabled="!isValidData" color="primary"
              depressed>Guardar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <div>&nbsp;</div>
    </v-card>
    <v-dialog v-model="isConfirmationOpen" max-width="500">
      <v-card>
        <v-card-title>
          <span class="font-weight-light" style="font-size: 16pt">Alerta!!</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" depressed small :color="buttonType" @click.stop="closeDialog">Aceptar</v-btn>
        </v-card-actions>
        <div>&nbsp;</div>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { loading, showMessage, rules } from '../../mixins'

export default {
  mixins: [loading, showMessage, rules],
  data: () => ({
    menu: false,
    isValidData: false,
    buttonType: 'primary',
    message: '',
    isConfirmationOpen: false,
    rfcs: [],
    globaAttributes: [],
    selectedRfc: 0,
    settingsData: {}
  }),
  computed: {
  },
  methods: {
    saveSettings () {
      this.settingsData.idRfc = this.selectedRfc

      return this.$proxy.post('api/Billing/SaveSettings', this.settingsData)
        .then((r) => {
          this.isConfirmationOpen = true
          this.message = r.data.message
          if (r.data.hasError === true) {
            this.buttonType = 'primary'
          } else {
            this.buttonType = 'error'
          }
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
          this.isConfirmationOpen = true
          this.message = 'Error!!'
          this.buttonType = 'error'
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    },
    closeDialog () {
      this.clearForm()
      this.$emit('cancel')
    },
    clearForm () {
      this.$refs.validData.resetValidation()
    },
    GetAllEmitters () {
      return this.$proxy.post('api/Billing/GetAllEmitters')
        .then((r) => {
          this.rfcs = r.data
          this.selectedRfc = this.rfcs.filter(i => i.isActive === true)[0].id
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    },
    GetAllGlobalAtrubutes () {
      return this.$proxy.post('api/Billing/GetAllGlobalAtrubutes')
        .then((r) => {
          this.globaAttributes = r.data
          this.settingsData.phone = this.globaAttributes.filter(i => i.name === 'Phone')[0].value
          this.settingsData.email = this.globaAttributes.filter(i => i.name === 'Email')[0].value
          this.settingsData.freeText = this.globaAttributes.filter(i => i.name === 'FreeText')[0].value
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    }
  },
  mounted () {
    this.GetAllEmitters()
    this.GetAllGlobalAtrubutes()
    this.settingsData = {
      phone: '',
      email: '',
      freeText: ''
    }
  },
  created () {
  }
}
</script>
<style></style>
