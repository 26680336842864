<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="8" sm="10">
            <span class="font-weight-light" style="font-size: 16pt">
              <h3>Facturas</h3>
            </span>
          </v-col>
          <v-col cols="1" sm="1" style="text-align: right;">
            <v-btn fab depressed small color="primary" @click.stop="getAllBillingsByRFC" title="Actualizar">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <v-btn fab depressed small color="primary" @click.stop="openAskForBillDialog" title="Agregar Factura">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div>&nbsp;</div>
        <v-card elevation="1">
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" dense outlined>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="billings" :search="search" :loading="isLoading" :options="options">
              <template v-slot:item.options="{ item }">
                <v-btn title="PDF" icon @click.stop="downloadPdfFile(item)">
                  <v-icon color="error">mdi-download</v-icon>
                </v-btn>
                <v-btn title="XML" icon @click.stop="downloadXmlFile(item)">
                  <v-icon color="primary">mdi-download</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="isAddBillingDialogOpen" max-width="600" persistent>
      <ask-for-billing :rfc="customer.rfc" @saved="validTiket"
        @cancel="isAddBillingDialogOpen = false"></ask-for-billing>
    </v-dialog>
  </v-container>
</template>

<script>

import { loading, showMessage, userInfo, customerInfo } from '../../mixins'
import { extractDateTime, formatDateTime } from '../../helpers/sharedFunctions'
import AskForBilling from '../customers/CustomerAskBilling'

export default {
  name: 'CustomerBills',
  components: { AskForBilling },
  mixins: [loading, showMessage, userInfo, customerInfo],
  props: ['bus'],
  data: () => ({
    isAddBillingDialogOpen: false,
    customer: {},
    billings: [],
    search: '',
    options: {
      itemsPerPage: 15,
      sortBy: ['Id'],
      sortDesc: [false]
    }
  }),
  computed: {
    headers () {
      return [
        { text: 'Opciones', value: 'options', align: 'center', width: '120px' },
        { text: 'Tiket', value: 'idTiket', align: 'center', sortable: true, filterable: true },
        { text: 'Nombre', value: 'name', align: 'center', sortable: true, filterable: true },
        { text: 'RFC', value: 'rfc', align: 'center', sortable: true, filterable: true },
        { text: 'CreationDate', value: 'creationDate', align: 'center', sortable: true, filterable: true },
        { text: 'Total', value: 'amount', align: 'center', sortable: true, filterable: true }
      ]
    }
  },
  methods: {
    downloadPdfFile (item) {
      window.open(process.env.VUE_APP_API_URL + item.pdfFile, '_blank')
    },
    downloadXmlFile (item) {
      window.open(process.env.VUE_APP_API_URL + item.xmlFile, '_blank')
    },
    openAskForBillDialog () {
      this.isAddBillingDialogOpen = true
    },
    closeAskForBillDialog () {
      this.isAddBillingDialogOpen = false
    },
    validTiket (item) {
      this.isAddBillingDialogOpen = false
      this.$router.push({ name: 'customerBilling', params: item })
    },
    getAllBillingsByRFC () {
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Billing/GetAllBillingsByRFC', this.customerInfo.rfc)
        .then((r) => {
          this.billings = r.data
          this.billings = this.billings.map(l => {
            l.creationDate = formatDateTime(new Date(extractDateTime(l.creationDate)))
            return l
          })
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    },
    selectCustomer (item, action) {
      this.$emit('itemSelected', item, action)
    }
  },
  mounted () {
    this.customer = this.customerInfo

    if (typeof this.customer.rfc === 'undefined') {
      this.$router.push({ name: 'home' })
      return
    }
    this.getAllBillingsByRFC()
  },
  created () {
    console.log(process.env.VUE_APP_API_URL)
  }
}
</script>
