<template>
  <v-form v-model="isValidData" ref="validData" enctype="multipart/form-data">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:center;">
            <h3>Ingresa los datos de tu tiket</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <div>&nbsp;</div>
      <v-card-text>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:left;">
            <v-text-field label="Número de tiket" :rules="[rules.required]" type="number" :disabled="isLoading"
              v-model="tiketInfo.idBilling"></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:left;">
            <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y
              min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="date" label="Fecha" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                  v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="date" :active-picker.sync="activePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                :min="(new Date((Date.now() - 30 * 24 * 60 * 60 * 1000) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                @change="save"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <div>&nbsp;</div>
      <v-card-actions>
        <v-row align="center" class="mx-4">
          <v-col style="text-align:center;">
            <v-btn :loading="isLoading" @click.stop="closeAskForBillDialog()" color="error" depressed>Cancelar</v-btn>
          </v-col>
          <v-col style="text-align:center;">
            <v-btn :loading="isLoading" @click.stop="CheckTiket()" :disabled="!isValidData" color="primary"
              depressed>Buscar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <div>&nbsp;</div>
    </v-card>
    <v-dialog v-model="isConfirmationOpen" max-width="500">
      <v-card>
        <v-card-title>
          <span class="font-weight-light" style="font-size: 16pt">Alerta!!</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" depressed small :color="buttonType"
            @click.stop="closeAskForBillDialog">Aceptar</v-btn>
        </v-card-actions>
        <div>&nbsp;</div>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { loading, showMessage, rules } from '../../mixins'

export default {
  props: ['rfc'],
  mixins: [loading, showMessage, rules],
  data: () => ({
    menu: false,
    tiketInfo: {},
    isValidData: false,
    buttonType: 'primary',
    message: '',
    isConfirmationOpen: false,
    date: null
  }),
  computed: {
  },
  methods: {
    closeAskForBillDialog () {
      this.tiketInfo.idBilling = null
      this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      this.$emit('cancel')
    },
    clearForm () {
      this.tiketInfo = {}
      this.$refs.validData.resetValidation()
    },
    CheckTiket () {
      this.changeLoadingStatus(true)
      this.tiketInfo.billingDate = this.date
      return this.$proxy.post('api/Billing/CheckTiket', this.tiketInfo.idBilling)
        .then((r) => {
          if (r.data.hasError === false) {
            this.GetBillingInfo()
          } else {
            this.isConfirmationOpen = true
            this.buttonType = 'error'
            this.message = r.data.message
            this.changeLoadingStatus(false)
          }
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
        })
    },
    GetBillingInfo () {
      this.tiketInfo.customerRFC = this.rfc
      return this.$proxy.post('api/Billing/GetBillingInfo', this.tiketInfo)
        .then((r) => {
          if (r.data?.Folio > 0) {
            this.clearForm()
            this.$emit('saved', r.data)
          } else {
            this.isConfirmationOpen = true
            this.buttonType = 'error'
            this.message = 'El tiket no existe !!'
          }
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    }
  },
  mounted () {
    this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
  },
  created () {
  }
}
</script>
<style></style>
