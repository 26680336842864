import { mapActions, mapGetters } from 'vuex'

const showMessage = {
  methods: {
    ...mapActions('snackbarModule', ['showMessage', 'showHttpErrorMessage'])
  }
}

const loading = {
  computed: {
    ...mapGetters(['isLoading'])
  },
  methods: {
    ...mapActions(['changeLoadingStatus'])
  }
}

const rules = {
  data: () => ({
    rules: {
      required: (v, message) => !!v || (message || 'Campo requerido'),
      maxCharacters: (v, max) => (v?.length <= max) || `Maximo ${max} caracteres`,
      minCharacters: (v, min) => (v?.length >= min) || `Minimo ${min} caracteres`,
      rfcCharacters: (v) => (v?.length > 13 || v?.length < 13) || 'El RFC debe contener 13 caracteres',
      requiredObjectWithId: (v, message) => !!(v?.id) || (message || 'Field required'),
      requiredArray: (v, message) => !!(v?.length > 0) || (message || 'Field required'),
      positiveInteger: (v, message) => (/^$|^\d+$/.test(v) && v > 0) || (message || 'Invalid value'),
      email: v => /^$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(?:[a-zA-Z]{2}|com|org|net|edu|gov|mil|biz|info|mobi|name|aero|asia|jobs|museum)$/.test(v) || 'Correo invalido!!',
      number: (v, message) => !isNaN(v) || (message || 'Invalid number'),
      noSpaces: (v) => !/\s/.test(v) || 'No spaces allowed'
    }
  })
}

const userInfo = {
  computed: {
    ...mapGetters('loginModule', ['userInfo', 'isAdmin'])
  }
}
const customerInfo = {
  computed: {
    ...mapGetters('customerModule', ['customerInfo'])
  }
}

export { loading, showMessage, rules, userInfo, customerInfo }
