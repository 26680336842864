<template>
  <v-form v-model="isRfcValid" ref="rfcValid" enctype="multipart/form-data">
    <v-card :loading="isLoading" class="mx-auto my-12" max-width="400" :disabled="isLoading">
      <div>&nbsp;</div>
      <v-row align="center" class="mx-12">
        <v-col style="text-align:center;">
          <v-img :src="require('../../assets/logo.jpg')"></v-img>
        </v-col>
      </v-row>
      <div>&nbsp;</div>
      <v-card-title>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:center;">
            <h3>Bienvenido!!</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <div>&nbsp;</div>
      <v-card-text>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:center;">
            <v-text-field v-on:keyup.enter="getCustomerInfoByRFC(rfc)" label="Introduce tu RFC" @input="onTextChange()"
              :rules="[rules.minCharacters(rfc, 12), rules.maxCharacters(rfc, 13)]" v-model="rfc"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" class="mx-4">
          <v-col style="text-align:center;">
            <v-btn @click.stop="getCustomerInfoByRFC(rfc)" :disabled="!isRfcValid" color="primary"
              depressed>Buscar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <div>&nbsp;</div>
    </v-card>
  </v-form>
</template>

<script>
import { loading, showMessage, rules } from '../../mixins'
import store from '../../store'

export default {
  name: 'CustomerLogin',
  mixins: [loading, showMessage, rules],
  data: () => ({
    isRfcValid: null,
    customer: {},
    rfc: ''
  }),
  computed: {
  },
  methods: {
    onTextChange () {
      this.reviewRFC()
    },
    reviewRFC () {
      if (this.rfc.length === 12 || this.rfc.length === 13) {
        this.$nextTick(() => {
          const validateRfc = require('validate-rfc')
          const res = validateRfc(this.rfc)
          this.isRfcValid = res.isValid
          if (res.isValid === false) {
            this.showMessage('RFC invalido')
          }
        })
      } else {
        this.isRfcValid = false
        // this.showMessage('RFC invalido')
      }
      // this.showMessage('RFC invalido')
      this.rfc = this.rfc.toUpperCase()
    },
    getCustomerInfoByRFC (rfc) {
      this.changeLoadingStatus(true)
      this.reviewRFC()
      if (this.isRfcValid === true && (this.rfc.length === 12 || this.rfc.length === 13)) {
        return this.$proxy.get(`api/User/GetCustomerInfoByRFC/${rfc}`)
          .then((r) => {
            store.dispatch('customerModule/login', r.data)
            // localStorage.setItem('user', JSON.stringify(r.data))
            this.$router.push({ name: 'customers', params: r.data })
          })
          .catch((e) => {
            this.showHttpErrorMessage(e)
          })
          .finally(() => {
            this.changeLoadingStatus(false)
          })
      } else {
        this.changeLoadingStatus(false)
      }
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>
