import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import CustomersList from '../components/customers/CustomersList.vue'
import CustomerBilling from '../components/customers/CustomerBilling.vue'
import AdminLogin from '../components/Admin/Login.vue'
import BillingList from '../components/Admin/BillingList.vue'
import Whatsapp from '../components/Admin/Whatsapp.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import NotAllowedPage from '../views/NotAllowedPage.vue'
import { role } from '../helpers/enums'
import store from '../store'

Vue.use(VueRouter)
var authenticationCheck = (to, from, next) => {
  if (to.meta.allowAnonymous) {
    next()
    return
  }

  const billingUserInfo = store.getters['billingUserModule/billingUserInfo']

  if (!billingUserInfo || !billingUserInfo.isAuthenticated) {
    next({ path: 'notAllowed' })
    return
  }

  if (to.meta.roles) {
    if (billingUserInfo.loginRoles.filter(e => to.meta.roles.indexOf(e) !== -1).length <= 0) {
      next({ path: 'notFound' })
    }
  }

  next()
}

const routes = [
  { path: '/', name: 'home', component: HomePage, meta: { allowAnonymous: true } },
  { path: '/customers', name: 'customers', component: CustomersList, meta: { allowAnonymous: true } },
  { path: '/customerBilling', name: 'customerBilling', component: CustomerBilling, meta: { allowAnonymous: true } },
  { path: '/AdminLogin', name: 'AdminLogin', component: AdminLogin, meta: { allowAnonymous: true } },
  { path: '/BillingList', name: 'BillingList', component: BillingList, meta: { roles: [role.ADMIN] } },
  { path: '/Whatsapp', name: 'Whatsapp', component: Whatsapp, meta: { roles: [role.ADMIN] } },
  { path: '/notAllowed', name: 'notAllowed', component: NotAllowedPage, meta: { allowAnonymous: true } },
  { path: '*', name: 'notFound', component: NotFoundPage, meta: { allowAnonymous: true } }
]

const router = new VueRouter({
  routes
})

router.beforeEach(authenticationCheck)

export default router
