<template>
  <v-container style="margin-top: -10px;">
    <v-row >
      <v-col cols="3" >
        <v-card>
          <v-toolbar>
            <v-toolbar-title class="text-h6">
              Mecapro Inbox
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text style="max-height: 750px; height: 750px; overflow-y: scroll;">
            <div class="search-wrapper">
              <v-text-field label="Buscar" v-model="search" placeholder="Buscar" @input="SearchOnchange"></v-text-field>
            </div>
            <div v-for="(item, index) in itemsResult" :key="item.index" class="contact" v-on:click="select($event)" :id="index" style="margin-left: -15px;">
              <v-row>
                <v-col cols="2">
                  <avatar :username="item.name" :size="50"></avatar>
                  <avatar v-if="item.numOfMessages > 0" :initials="item?.numOfMessages.toString()" backgroundColor="#25d366" color="white" :size="25" style="margin-top: -20px; margin-left: 30px;"></avatar>
                </v-col>
                <v-col cols="6" align="left">
                  <div>
                    {{ item.name }}<br />
                    {{ item.phoneNumber }}
                  </div>
                </v-col>
                <v-col cols="4" align="right">
                  {{ format_date(item.sentDate) }}<br />
                  {{ format_time(item.sentDate) }}
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="7">
        <v-card class="chat-background card-outter" height="815px">
          <v-toolbar>
            <div class="text-h6">
              {{ selectedCustomer }}
            </div>
          </v-toolbar>
          <v-card-text style="max-height: 700px; height: 700px; overflow: auto;" id="MessagesContainer">
            <div v-for="(item, index) in messages" :key="item.index" :id="index">
              <div align="left" v-if="item.isResponse === false">
                <v-flex class="text-xs-right" xs12 mb-4>
                  <v-chip class="chip-chat1" :title="item.message">
                    {{ item.message }}<v-icon right>mdi-check-all</v-icon>
                  </v-chip>
                </v-flex>
              </div>
              <div align="right" v-if="item.isResponse === true">
                <v-flex class="text-xs-right" xs12 mb-4>
                  <v-chip class="chip-chat" :title="item.message">
                    {{ item.message }}<v-icon right>mdi-check-all</v-icon>
                  </v-chip>
                </v-flex>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="card-actions" style="width: 100%; background-color: white; padding: 0px;" >
                <v-text-field
                  style="margin-left: 20px;"
                  single-line
                  color="teal"
                  class="my-input"
                  @keydown.enter="SendCustomeMessage"
                  v-model="outputMessage"
                  outline
                  label="Escribe Mensaje"
                  placeholder="Escribe Mensaje">
                </v-text-field>

                <v-btn
                  style="margin-left: 10px; margin-right: 20px;"
                  large
                  class="teal--text"
                  icon
                  @click="SendCustomeMessage"
                  target="_blank"
                >
                  <v-icon large>mdi-send</v-icon>
                </v-btn>

                <v-btn
                  style="margin-left: 10px; margin-right: 20px;"
                  large
                  class="teal--text"
                  icon
                  @click="WelcomeSendMessage"
                  target="_blank"
                >
                  <v-icon large>mdi-human-greeting</v-icon>
                </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card>
          <v-toolbar>
            <v-toolbar-title class="text-h6">
              Mecapro Contactos
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text style="max-height: 750px; height: 750px; overflow-y: scroll; overflow-x: hidden;">
            <div class="search-wrapper">
              <v-text-field label="Buscar" v-model="searchContacts" placeholder="Buscar" @input="SearchContactsOnchange"></v-text-field>
            </div>
            <div v-for="(item, index) in contacts" :key="item.index" class="contact" v-on:click="selectContact($event)" :id="index.toString() + 'a'" style="margin-left: -15px; width: 350px;">
              <v-row>
                <v-col cols="2">
                  <avatar :username="item.name" :size="50"></avatar>
                </v-col>
                <v-col cols="6" align="left">
                  <div>
                    {{ item.name }}<br />
                    {{ item.phoneNumber }}
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="isContactDialogOpen" max-width="600" persistent>
      <a>a</a>
    </v-dialog>
  </v-container>
</template>

<script>
import { loading, showMessage, userInfo, customerInfo } from '../../mixins'
import Avatar from 'vue-avatar'
import moment from 'moment'

export default {
  name: 'Whatsapp',
  components: { Avatar },
  mixins: [loading, showMessage, userInfo, customerInfo],
  data: () => ({
    isContactDialogOpen: false,
    items: [],
    itemsResult: [],
    contacts: [],
    messages: [],
    search: '',
    searchContacts: '',
    selectedPhoneNumber: '',
    selectedCustomer: '',
    outputMessage: '',
    WhatsAppMessageModel: {
      customerName: '',
      customerPhone: '',
      message: '',
      messageId: ''
    }

  }),
  computed: {
  },
  methods: {
    select: function (event) {
      var targetId = event.currentTarget.id
      for (var i = 0; i < this.itemsResult?.length; i++) {
        document.getElementById(i).className = 'contact'
      }
      document.getElementById(targetId).className = 'selected'
      this.selectedPhoneNumber = this.itemsResult[targetId].phoneNumber
      this.selectedCustomer = this.itemsResult[targetId].name
      this.GetWhatsappMessagesByPhoneNumber()
    },
    selectContact: function (event) {
      var targetId = event.currentTarget.id
      for (var i = 0; i < this.contacts?.length; i++) {
        document.getElementById(i.toString() + 'a').className = 'contact'
      }
      document.getElementById(targetId).className = 'selected'
      this.selectedPhoneNumber = this.contacts[targetId.slice(0, -1)].phoneNumber
      this.selectedCustomer = this.contacts[targetId.slice(0, -1)].name
      this.GetWhatsappMessagesByPhoneNumber()
    },
    format_date (value) {
      if (value) {
        return moment(String(value)).format('YYYY/MM/DD')
      }
    },
    format_time (value) {
      if (value) {
        return moment(String(value)).format('hh:mm')
      }
    },
    SearchOnchange () {
      if (this.search?.length > 0) {
        this.itemsResult = this.items.filter(r => r.name.toLowerCase().includes(this.search.toLowerCase()))
      } else {
        this.itemsResult = this.items
      }
    },
    SearchContactsOnchange () {
      if (this.searchContacts?.length > 0) {
        this.contacts = this.GetAllWhatsAppMessages()
      } else {
        this.contacts = []
      }
    },
    GetWhatsAppMessages () {
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Whatsapp/GetWhatsAppMessages')
        .then((r) => {
          this.items = r.data
          this.itemsResult = r.data
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    },
    GetAllWhatsAppMessages () {
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Whatsapp/GetAllWhatsAppMessages', this.searchContacts)
        .then((r) => {
          this.contacts = r.data
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    },
    SendCustomeMessage () {
      // var count = this.messages.filter(r => r.isResponse === true).length
      // this.WhatsAppMessageModel.messageId = JSON.parse(this.messages.filter(r => r.isResponse === true)[count - 1].jsonString).entry[0].changes[0].value.messages[0].id
      this.WhatsAppMessageModel.message = this.outputMessage
      this.WhatsAppMessageModel.customerPhone = this.selectedPhoneNumber
      this.WhatsAppMessageModel.customerName = this.selectedCustomer
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Whatsapp/SendCustomeMessage', this.WhatsAppMessageModel)
        .then((r) => {
          this.GetWhatsappMessagesByPhoneNumber()
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
          this.outputMessage = ''
        })
    },
    WelcomeSendMessage () {
      this.WhatsAppMessageModel.message = this.outputMessage
      this.WhatsAppMessageModel.customerPhone = this.selectedPhoneNumber
      this.WhatsAppMessageModel.customerName = this.selectedCustomer
      this.WhatsAppMessageModel.templateName = 'hello'
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Whatsapp/WelcomeSendMessage', this.WhatsAppMessageModel)
        .then((r) => {
          this.GetWhatsappMessagesByPhoneNumber()
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
          this.outputMessage = ''
        })
    },
    GetWhatsappMessagesByPhoneNumber () {
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Whatsapp/GetWhatsappMessagesByPhoneNumber', this.selectedPhoneNumber)
        .then((r) => {
          this.messages = r.data
          this.messages.forEach((value, index) => {
            if (value.notificationType === 1) {
              this.messages[index].message = 'Notificacion de servicio'
            }
            if (value.notificationType === 2) {
              this.messages[index].message = JSON.parse(this.messages[index].message).text.body
            }
            if (value.notificationType === 3) {
              this.messages[index].message = JSON.parse(this.messages[index].message).template.name
            }
          })
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
          var objDiv = document.getElementById('MessagesContainer')
          objDiv.scrollTop = objDiv.scrollHeight
        })
    }
  },
  mounted () {
    this.GetWhatsAppMessages()
    this.GetAllWhatsAppMessages()
  },
  created () {
    window.setInterval(() => {
      this.GetWhatsAppMessages()
    }, 10000)

    window.setInterval(() => {
      if (this.selectedPhoneNumber.length > 0) {
        this.GetWhatsappMessagesByPhoneNumber()
      }
    }, 3000)
  }
}
</script>

<style>
.contact {
  padding: 10px;
}

.contact:hover {
  opacity: 0.5;
  cursor: pointer;
}

.selected {
  background: #ccc;
  padding: 10px;
}

.selected:hover {
  opacity: 0.5;
  cursor: pointer;
}
.chat-background {
  background-color: #ECE5DD !important
}

.chip-chat {
  background-color: #DCF8C6 !important
}

.chip-chat1 {
  background-color: #FFF !important
}

.card-actions {
  position: absolute;
  bottom: 0;
}

.my-input.v-input .v-input__slot {
  border-radius: 100px;
}

</style>
