const state = {
  customerInfo: { isAuthenticated: false }
}

const getters = {
  customerInfo: (state) => {
    return state.customerInfo
  },
  isAdmin: (state) => {
    return state.customerInfo.roleNames.includes('Admin')
  }
}

const actions = {
  login (context, customerInfo) {
    context.commit('login', customerInfo)
  },
  logout (context) {
    context.commit('logout')
  }
}

const mutations = {
  login (state, customerInfo) {
    state.customerInfo = Object.assign({ isAuthenticated: true }, customerInfo)
  },
  logout (state) {
    state.customerInfo = { isAuthenticated: false }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
