<template>
  <v-container>
    <div>
      <v-form v-model="isBillingValid" ref="billingValid" enctype="multipart/form-data">
        <v-row>
          <v-col cols="12">
            <v-row justify="space-between">
              <v-col cols="8" sm="10">
                <span class="font-weight-light" style="font-size: 16pt">
                  <h3>Facturacion Electronica</h3>
                </span>
              </v-col>
            </v-row>
            <div>&nbsp;</div>
            <v-card elevation="1" :disabled="isLoading" :loading="isLoading">
              <v-card-title>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <h3>Tiket de compra</h3>
                      </v-card-title>
                      <v-card-text>
                        <span style="font-weight: bold;">Fecha de compra:</span> {{ formatted(billingData.Fecha) }}
                        <div>&nbsp;</div>
                        <div>
                          <v-row>
                            <v-col cols="3" style="text-align: center;">
                              <span style="font-weight: bold;">Cantidad</span>
                            </v-col>
                            <v-col cols="6">
                              <span style="font-weight: bold;">Descripción</span>
                            </v-col>
                            <v-col cols="3" style="text-align: center;">
                              <span style="font-weight: bold;">Precio</span>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-for="item in billingData.Conceptos?.filter(i => i.IsDiscountRecord === true)" v-show="billingData.ExtraData.HasDiscount === true" v-bind:key="item.id">
                          <v-row>
                            <v-col cols="3" style="text-align: center;">
                              <span>{{ item.Cantidad }}</span>
                            </v-col>
                            <v-col cols="6">
                              <span>{{ item.Descripcion }}</span>
                            </v-col>
                            <v-col cols="3" style="text-align: center;">
                              <span>{{ (item.Importe + item.Impuestos.Traslados[0].Importe).toFixed(2) }}</span>
                            </v-col>
                          </v-row>
                        </div>
                        <div v-for="item in billingData.Conceptos?.filter(i => i.IsDiscountRecord === false)" v-show="billingData.ExtraData.HasDiscount === false" v-bind:key="item.id">
                          <v-row>
                            <v-col cols="3" style="text-align: center;">
                              <span>{{ item.Cantidad }}</span>
                            </v-col>
                            <v-col cols="6">
                              <span>{{ item.Descripcion }}</span>
                            </v-col>
                            <v-col cols="3" style="text-align: center;">
                              <span>{{ (item.Importe + item.Impuestos.Traslados[0].Importe).toFixed(2) }}</span>
                            </v-col>
                          </v-row>
                        </div>
                        <div>
                          <v-row>
                            <v-col cols="3">

                            </v-col>
                            <v-col cols="6">

                            </v-col>
                            <v-col cols="3" style="text-align: center;">
                              <span><span style="font-weight: bold;">Total:</span>&nbsp;
                              {{ billingData.Total?.toFixed(2) }}</span>
                            </v-col>
                          </v-row>
                        </div>
                        <div>&nbsp;</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <h3>Datos del comprador</h3>
                      </v-card-title>
                      <v-card-text>
                        <div>&nbsp;</div>
                        <v-row>
                          <v-col cols="3">
                            <v-text-field :disabled="!(typeof this.customerInfo.rfc === 'undefined')" label="RFC"
                              :rules="[rules.required]" v-model="customerInfo.rfc"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field :disabled="!(typeof this.customerInfo.name === 'undefined')" label="Nombre"
                              :rules="[rules.required]" v-model="customerInfo.name"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field :disabled="!(typeof this.customerInfo.lastName === 'undefined')"
                              label="Apellido Paterno" v-model="customerInfo.lastName"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field :disabled="!(typeof this.customerInfo.secondLastName === 'undefined')"
                              label="Apellido Materno" v-model="customerInfo.secondLastName"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="date" label="Fecha de nacimiento" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="date" :active-picker.sync="activePicker" :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)" min="1920-01-01" @change="save"></v-date-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field label="Telefono"
                              v-model="customerInfo.phone"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field label="Celular" :rules="[rules.required]"
                              v-model="customerInfo.celphone"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field label="Correo Electronico" :rules="[rules.required]"
                              v-model="customerInfo.mail3"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field label="Calle" :rules="[rules.required]"
                              v-model="customerInfo.street"></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field label="Número" :rules="[rules.required]"
                              v-model="customerInfo.number"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field label="Colonia" :rules="[rules.required]"
                              v-model="customerInfo.suburb"></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field label="C.P." :rules="[rules.required]" type="number"
                              v-model="customerInfo.postalCode"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field label="Estado" :rules="[rules.required]"
                              v-model="customerInfo.state"></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field label="Micipio" :rules="[rules.required]"
                              v-model="customerInfo.city"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <!-- <v-col cols="3">
                          <v-select label="Razon Social" :rules="[rules.required]" v-model="selectedRasonSocial"
                            :items="razonSocial" item-text="descripcion" item-value="id" dense outlined />
                        </v-col> -->
                          <v-col cols="6">
                            <!-- selectedCFDI -->
                            <v-select label="Uso CFDI" :rules="[rules.required]" v-model="customerInfo.idCfdi"
                              item-text="descripcion" :items="cfdi" item-value="id" dense outlined />
                          </v-col>
                          <v-col cols="6">
                            <v-select label="Regimen Fiscal" :rules="[rules.required]" v-model="customerInfo.idRegimen"
                              item-text="descripcion"
                              :items="filteredRF = regimenFiscal.filter(i => i.cfdi.some(j => j.codeC.includes(cfdi.filter(i => i.id === customerInfo.idCfdi)[0]?.codigo) === true))"
                              item-value="id" dense outlined />
                          </v-col>
                        </v-row>
                        <div>&nbsp;</div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
              <div>&nbsp;</div>
              <v-card-actions>
                <v-row align="center" class="mx-4">
                  <v-col style="text-align:center;">
                    <v-btn @click.stop="DoBilling()" :disabled="!isBillingValid" color="primary"
                      depressed>Facturar</v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
              <div>&nbsp;</div>
            </v-card>
            <div>&nbsp;</div>
          </v-col>
        </v-row>
        <div>&nbsp;</div>
      </v-form>
    </div>
    <v-dialog v-model="isConfirmationOpen" max-width="500">
      <v-card>
        <v-card-title>
          <span class="font-weight-light" style="font-size: 16pt">Alerta!!</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" depressed small :color="buttonType" @click.stop="confirm">Aceptar</v-btn>
        </v-card-actions>
        <div>&nbsp;</div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { loading, showMessage, customerInfo, rules } from '../../mixins'
import moment from 'moment'
import MessageDialog from '../Shared/MessageDialog.vue'

export default {
  name: 'CustomerBilling',
  mixins: [loading, showMessage, customerInfo, rules],
  props: ['bus'],
  component: { MessageDialog },
  data: () => ({
    billingData: {},
    buttonType: 'primary',
    message: '',
    isConfirmationOpen: false,
    activePicker: null,
    date: '2022-01-04',
    menu: false,
    isBillingValid: null,
    selectedRasonSocial: null,
    selectedCFDI: null,
    selectedRegimenFiscal: null,
    razonSocial: [
      {
        id: 1,
        descripcion: 'Fisica'
      },
      {
        id: 2,
        descripcion: 'Moral'
      }
    ],
    cfdi: [
      {
        id: 1,
        codigo: 'G01',
        descripcion: 'Adquisición de mercancías'
      },
      {
        id: 2,
        codigo: 'G02',
        descripcion: 'Devoluciones, descuentos o bonificaciones'
      },
      {
        id: 3,
        codigo: 'G03',
        descripcion: 'Gastos en general'
      },
      {
        id: 4,
        codigo: 'S01',
        descripcion: 'Sin efectos fiscales'
      },
      {
        id: 5,
        codigo: 'CP01',
        descripcion: 'Pagos'
      }
    ],
    regimenFiscal: [
      {
        id: 1,
        code: '601',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'General de Ley Personas Morales'
      },
      {
        id: 2,
        code: '603',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Personas Morales con Fines no Lucrativos'
      },
      {
        id: 3,
        code: '605',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 1,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Sueldos y Salarios e Ingresos Asimilados a Salarios'
      },
      {
        id: 4,
        code: '606',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Arrendamiento'
      },
      {
        id: 5,
        code: '607',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 2,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Régimen de Enajenación o Adquisición de Bienes'
      },
      {
        id: 6,
        code: '608',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 2,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Demás ingresos'
      },
      {
        id: 7,
        code: '609',
        cfdi: [],
        descripcion: 'Consolidación'
      },
      {
        id: 8,
        code: '610',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 2,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Residentes en el Extranjero sin Establecimiento Permanente en México'
      },
      {
        id: 9,
        code: '611',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 2,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Ingresos por Dividendos (socios y accionistas)'
      },
      {
        id: 10,
        code: '612',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Personas Físicas con Actividades Empresariales y Profesionales'
      },
      {
        id: 11,
        code: '614',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 2,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Ingresos por intereses'
      },
      {
        id: 12,
        code: '615',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 2,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Régimen de los ingresos por obtención de premios'
      },
      {
        id: 13,
        code: '616',
        cfdi: [
          {
            idC: 1,
            codeC: 'S01'
          },
          {
            idC: 2,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Sin obligaciones fiscales'
      },
      {
        id: 14,
        code: '620',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'
      },
      {
        id: 15,
        code: '621',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Incorporación Fiscal'
      },
      {
        id: 16,
        code: '622',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'
      },
      {
        id: 17,
        code: '623',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Opcional para Grupos de Sociedades'
      },
      {
        id: 18,
        code: '624',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Coordinados'
      },
      {
        id: 19,
        code: '625',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'
      },
      {
        id: 20,
        code: '626',
        cfdi: [
          {
            idC: 1,
            codeC: 'G01'
          },
          {
            idC: 2,
            codeC: 'G02'
          },
          {
            idC: 3,
            codeC: 'G03'
          },
          {
            idC: 4,
            codeC: 'S01'
          },
          {
            idC: 5,
            codeC: 'CP01'
          }
        ],
        descripcion: 'Régimen Simplificado de Confianza'
      }
    ]
  }),
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    date (val) {
      customerInfo.birdDate = val
    }
  },
  computed: {

  },
  methods: {
    confirm () {
      this.isConfirmationOpen = false
      this.$router.push({ name: 'customers' })
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    formatted (value) {
      return moment(String(value)).format('YYYY-MM-DD')
    },
    DoBilling () {
      this.changeLoadingStatus(true)
      this.billingData.Receptor.Rfc = this.customerInfo.rfc
      this.billingData.Receptor.Nombre = this.customerInfo.name + ' ' + this.customerInfo.lastName + ' ' + this.customerInfo.secondLastName
      this.customerInfo.birthDay = this.date
      if (this.billingData.Receptor.Rfc === 'XAXX010101000') {
        this.billingData.Receptor.DomicilioFiscalReceptor = this.billingData.LugarExpedicion
      } else {
        this.billingData.Receptor.DomicilioFiscalReceptor = this.customerInfo.postalCode
      }
      this.billingData.Receptor.RegimenFiscalReceptor = this.regimenFiscal.filter(i => i.id === this.customerInfo.idRegimen)[0].code
      this.billingData.Receptor.UsoCFDI = this.cfdi.filter(i => i.id === this.customerInfo.idCfdi)[0].codigo
      this.billingData.CustomerInfo = this.customerInfo

      return this.$proxy.post('api/Billing/DoBilling', this.billingData)
        .then((r) => {
          this.isConfirmationOpen = true
          if (r.data.hasError === true) {
            this.buttonType = 'error'
          } else {
            this.buttonType = 'primary'
          }
          this.message = r.data.message
          // showMessage(r.data.message)
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    }
  },
  mounted () {
    this.billingData = this.$route.params
    if (typeof this.billingData.Folio === 'undefined') {
      this.$router.push({ name: 'home' })
    }
    if (this.customerInfo.birthDay === null) {
      this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    } else {
      this.date = this.formatted(this.customerInfo.birthDay)
    }
  },
  created () {
  }
}
</script>
<style>
