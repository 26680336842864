<template>
  <v-form v-model="isValidData" ref="validData" enctype="multipart/form-data">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:center;">
            <h3>Cancelacion de factura</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <div>&nbsp;</div>
      <v-card-text>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:left;">
            <v-select label="Motivo de cancelacion" :rules="[rules.required]" v-model="selectedReason"
              item-text="descripcion" :items="motivos" item-value="id" dense outlined />
          </v-col>
        </v-row>
        <v-row align="center" class="mx-12" v-show="selectedReason === '01'">
          <v-col style="text-align:left;">
            <v-text-field label="Folio" :rules="[rules.required]" type="number" :disabled="isLoading"
              v-model="cancellationFolio"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <div>&nbsp;</div>
      <v-card-actions>
        <v-row align="center" class="mx-4">
          <v-col style="text-align:center;">
            <v-btn :loading="isLoading" @click.stop="closeCancelBilling()" color="error" depressed>Cerrar</v-btn>
          </v-col>
          <v-col style="text-align:center;">
            <v-btn :loading="isLoading" @click.stop="CancelBilling()" :disabled="!isValidData && (selectedReason === '01')" color="primary"
              depressed>Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <div>&nbsp;</div>
    </v-card>
    <v-dialog v-model="isConfirmationOpen" max-width="500">
      <v-card>
        <v-card-title>
          <span class="font-weight-light" style="font-size: 16pt">Alerta!!</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" depressed small :color="buttonType" @click.stop="closeCancelBilling">Aceptar</v-btn>
        </v-card-actions>
        <div>&nbsp;</div>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { loading, showMessage, rules } from '../../mixins'

export default {
  props: ['item'],
  mixins: [loading, showMessage, rules],
  data: () => ({
    menu: false,
    tiketInfo: {},
    isValidData: false,
    buttonType: 'primary',
    message: '',
    isConfirmationOpen: false,
    selectedReason: null,
    cancellationFolio: null,
    cancellation: {},
    motivos: [
      {
        id: '01',
        descripcion: 'Comprobante emitido con errores con relación'
      },
      {
        id: '02',
        descripcion: 'Comprobante emitido con errores sin relación'
      },
      {
        id: '03',
        descripcion: 'No se llevo acabo la operación'
      },
      {
        id: '04',
        descripcion: 'Operación nominativa relacionada en una factura global'
      }
    ]
  }),
  computed: {
  },
  methods: {
    closeCancelBilling () {
      this.$emit('cancel')
    },
    CancelBilling () {
      this.changeLoadingStatus(true)
      this.cancellation = {
        id: this.item.id,
        idTiket: this.item.idTiket,
        motivo: this.selectedReason,
        folioSustituye: this.cancellationFolio
      }
      this.$proxy.post('api/Billing/CancelBilling', this.cancellation)
        .then((r) => {
          this.isConfirmationOpen = true
          this.message = r.data.message
          if (r.data.hasError === true) {
            this.buttonType = 'primary'
          } else {
            this.buttonType = 'error'
          }
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
          this.isConfirmationOpen = true
          this.message = 'Error!!'
          this.buttonType = 'error'
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    }
  },
  mounted () {
    this.selectedReason = null
    this.cancellationFolio = null
    this.cancellation = {
      id: 0,
      idTiket: this.folio,
      motivo: this.selectedReason,
      folioSustituye: this.cancellationFolio
    }
  },
  created () {
  }
}
</script>
<style></style>
