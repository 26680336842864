const extractDateTime = function (dateString) {
  try {
    return `${dateString.split('T')[0]} ${dateString.split('T')[1].substring(0, 8)}`
  } catch (e) {
    return ''
  }
}

const formatDateTime = function (date) {
  const y = date.getFullYear()
  const M = ((date.getMonth() + 1).toString().padStart(2, '0'))
  const d = (date.getDate().toString().padStart(2, '0'))
  const h = (date.getHours()).toString().padStart(2, '0')
  const m = (date.getMinutes()).toString().padStart(2, '0')
  const s = (date.getSeconds()).toString().padStart(2, '0')
  return `${y}/${M}/${d} ${h}:${m}:${s}`
}

const formatDate = function (date, separator = '/') {
  const y = date.getFullYear()
  const M = ((date.getMonth() + 1).toString().padStart(2, '0'))
  const d = (date.getDate().toString().padStart(2, '0'))
  return `${y}${separator}${M}${separator}${d}`
}

const cutText = function (text, length) {
  if (text.length > length) {
    return `${text.substring(0, length)}...`
  }
  return text
}

const orderBy = name => {
  return function (a, b) {
    if (a[name] > b[name]) {
      return 1
    }
    if (a[name] < b[name]) {
      return -1
    }
    return 0
  }
}

export { extractDateTime, formatDateTime, cutText, formatDate, orderBy }
