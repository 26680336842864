<template>
  <v-card>
    <v-card-title>
      <span>
        <slot name="title">Confirmar</slot>
      </span>
    </v-card-title>
    <v-card-text>
      <slot></slot>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn small depressed @click="confirm()" :color="confirmationButtonColor">
        Aceptar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'ConfirmationDialog',
  props: {
    confirmationButtonColor: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    confirm (val) {
      this.$emit('confirm')
    }
  }
}
</script>
