const state = {
  userInfo: { isAuthenticated: false }
}

const getters = {
  userInfo: (state) => {
    return state.userInfo
  },
  isAdmin: (state) => {
    return state.userInfo.roleNames.includes('Admin')
  }
}

const actions = {
  login (context, userInfo) {
    context.commit('login', userInfo)
  },
  logout (context) {
    context.commit('logout')
  }
}

const mutations = {
  login (state, userInfo) {
    state.userInfo = Object.assign({ isAuthenticated: true }, userInfo)
  },
  logout (state) {
    state.userInfo = { isAuthenticated: false }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
