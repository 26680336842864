import Snackbar from '../helpers/snackbar'

const state = {
  snackbar: new Snackbar()
}

const getters = {
  snackbar: (state) => {
    return state.snackbar
  }
}

const actions = {
  showMessage (context, text) {
    context.commit('showMessage', text)
  },
  hideMessage (context) {
    context.commit('hideMessage')
  },
  showHttpErrorMessage (context, e) {
    let text = 'Connection error'

    if (typeof e.response !== 'undefined') {
      const status = e.response.status
      const defaultText = () => `${status} - Something is wrong`

      switch (status) {
        case 400: {
          try {
            // text = (e.response.data.errors[Object.keys(e.response.data.errors)[0]][0])
            text = e.response.data
            if (!text) {
              text = (e.response.data.errors[Object.keys(e.response.data.errors)[0]][0])
              if (!text) {
                text = defaultText()
              }
            }
          } catch (err) {
            text = defaultText()
          }
          break
        }
        case 404: {
          text = `${status} - Not found`
          break
        }
        case 409: {
          text = e.response.data
          if (!text) {
            text = defaultText()
          }
          break
        }
        case 500: {
          text = `${status} - Server error`
          break
        }
        default: {
          text = defaultText()
          break
        }
      }
    }
    context.commit('showMessage', text)
  }
}

const mutations = {
  showMessage (state, text) {
    state.snackbar.text = text
    state.snackbar.visible = true
  },
  hideMessage (state) {
    state.snackbar.visible = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
