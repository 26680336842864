<template>
  <v-app id="inspire">
    <v-container>
      <v-app-bar app clipped-left color="#00469C" flat dark>
        <v-row no-gutters justify="space-between">
          <v-col style="margin-top:20px;">
            <h2>&nbsp;&nbsp;{{ customerInfo.name }} {{ customerInfo.lastName }} {{ customerInfo.secondLastName }} </h2>
          </v-col>
          <v-col cols="auto" class="align-center">
            <v-img :src="require('@/assets/logo.png')" max-width="55px" style="margin-top:3px;"></v-img>
          </v-col>
        </v-row>
      </v-app-bar>
    </v-container>
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </v-slide-y-transition>
      <v-snackbar
        v-model="snackbar.visible"
        :bottom="snackbar.y === 'bottom'"
        :left="snackbar.x === 'left'"
        :multi-line="snackbar.mode === 'multi-line'"
        :right="snackbar.x === 'right'"
        :timeout="snackbar.timeout"
        :top="snackbar.y === 'top'"
        :vertical="snackbar.mode === 'vertical'"
      >
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            color="primary"
            @click="hideMessage"
            text
            small
            v-bind="attrs"
          >
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <v-card-text class="py-2 white--text text-center">
          Copyright 2022 Mecapro.com.mx ©, todos los derechos reservados
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { customerInfo } from './mixins'

export default {
  name: 'App',
  data: () => ({
    isDrawerOpen: false,
    setValue: false
  }),
  mixins: [customerInfo],
  computed: {
    ...mapGetters('snackbarModule', ['snackbar'])
  },
  methods: {
    ...mapActions('snackbarModule', ['hideMessage'])
  }
}
</script>
