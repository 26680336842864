import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

export default {
  install (Vue) {
    Vue.prototype.$proxy = {}

    Vue.prototype.$proxy.get = function (path) {
      return new Promise((resolve, reject) => {
        if (path.indexOf('?') > 0) {
          path = `${path}&ts=${+new Date()}`
        } else {
          path = `${path}?ts=${+new Date()}`
        }
        return axios.get(path)
          .then((d) => {
            resolve(d)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }

    Vue.prototype.$proxy.getDownload = function (path) {
      return new Promise((resolve, reject) => {
        if (path.indexOf('?') > 0) {
          path = `${path}&ts=${+new Date()}`
        } else {
          path = `${path}?ts=${+new Date()}`
        }
        return axios({
          method: 'get',
          url: path,
          responseType: 'blob'
        })
          .then((r) => {
            resolve(r)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }

    Vue.prototype.$proxy.post = function (path, payload = {}) {
      return new Promise((resolve, reject) => {
        return axios.post(path, payload, { headers: { 'Content-Type': 'application/json' } })
          .then((d) => {
            resolve(d)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }

    Vue.prototype.$proxy.postMultipart = function (path, payload = {}) {
      return new Promise((resolve, reject) => {
        return axios.post(path, payload, { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((d) => {
            resolve(d)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }

    Vue.prototype.$proxy.put = function (path, payload = {}) {
      return new Promise((resolve, reject) => {
        return axios.put(path, payload)
          .then((d) => {
            resolve(d)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }

    Vue.prototype.$proxy.delete = function (path) {
      return new Promise((resolve, reject) => {
        return axios.delete(path)
          .then((d) => {
            resolve(d)
          })
          .catch((e) => {
            reject(e)
          })
      })
    }
  }
}
