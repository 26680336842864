import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/httpProxy'
import GAuth from 'vue-google-oauth2'

Vue.config.productionTip = false

const gauthOption = {
  clientId: '817517284293-g775s6k0lsifecbbf8vj6ivc44rc41qs.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
}
Vue.use(GAuth, gauthOption)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
