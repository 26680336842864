<template>
  <v-form v-model="isDataValid" ref="BillingLogin" enctype="multipart/form-data">
    <v-card :loading="isLoading" class="mx-auto my-12" max-width="400" :disabled="isLoading">
      <div>&nbsp;</div>
      <v-row align="center" class="mx-12">
        <v-col style="text-align:center;">
          <v-img :src="require('../../assets/logo.jpg')"></v-img>
        </v-col>
      </v-row>
      <div>&nbsp;</div>
      <v-card-title>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:center;">
            <h3>Bienvenido!!</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <div>&nbsp;</div>
      <v-card-text>
        <v-row align="center" class="mx-12">
          <v-col style="text-align:center;">
            <v-text-field v-on:keyup.enter="BillingLogin()" label="Usuario" required
              v-model="BillingUserModel.userName"></v-text-field>
            <v-text-field type="password" v-on:keyup.enter="BillingLogin()" label="Contraseña" required
              v-model="BillingUserModel.password"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" class="mx-4">
          <v-col style="text-align:center;">
            <v-btn @click.stop="BillingLogin()" :disabled="!isDataValid" color="primary" depressed>Entrar</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <div>&nbsp;</div>
    </v-card>
    <v-dialog v-model="isConfirmationOpen" max-width="500">
      <v-card>
        <v-card-title>
          <span class="font-weight-light" style="font-size: 16pt">Alerta!!</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" depressed small :color="buttonType"
            @click.stop="closeAskForBillDialog">Aceptar</v-btn>
        </v-card-actions>
        <div>&nbsp;</div>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { loading, showMessage, rules } from '../../mixins'
import store from '../../store'

export default {
  name: 'BillingLogin',
  mixins: [loading, showMessage, rules],
  data: () => ({
    isDataValid: false,
    BillingUserModel: {},
    isConfirmationOpen: false,
    message: '',
    buttonType: 'primary'
  }),
  methods: {
    closeAskForBillDialog () {
      this.clearForm()
      this.isConfirmationOpen = false
    },
    clearForm () {
      this.BillingUserModel = {}
      this.$refs.BillingLogin.resetValidation()
    },
    BillingLogin () {
      this.changeLoadingStatus(true)
      return this.$proxy.post('api/User/BillingLogin', this.BillingUserModel)
        .then((r) => {
          if (r.data?.idUser > 0) {
            store.dispatch('billingUserModule/login', r.data)
            this.$router.push({ name: 'BillingList' })
          } else {
            this.isConfirmationOpen = true
            this.buttonType = 'error'
            this.message = 'Usuario y/o contraseña incorrecto(s) !!'
          }
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
          this.isConfirmationOpen = true
          this.buttonType = 'error'
          this.message = 'Usuario y/o contraseña incorrecto(s) !!'
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>
