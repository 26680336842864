<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="8" sm="10">
            <span class="font-weight-light" style="font-size: 16pt">
              <h3>Facturas</h3>
              <h5>total Facturas: {{ counter }}</h5>
            </span>
          </v-col>
          <v-col cols="2" sm="2" style="text-align: right;">
            <v-btn fab depressed small color="primary" @click.stop="GetAllBillings" title="Actualizar">
              <v-icon>mdi-reload</v-icon>
            </v-btn>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <v-btn fab depressed small color="primary" @click.stop="openConfig" title="Configuraciones">
              <v-icon>mdi-content-save-settings</v-icon>
            </v-btn>
            <span>&nbsp;&nbsp;&nbsp;</span>
            <v-btn fab depressed small color="primary" @click.stop="openWhatsapp" title="Configuraciones">
              <v-icon>mdi-whatsapp</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <div>&nbsp;</div>
        <v-card elevation="1">
          <v-card-title>
            <v-row no-gutters>
              <v-col cols="12" sm="6" md="2">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" dense outlined>
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="billings" :search="search" :loading="isLoading" :options="options">
              <template v-slot:item.options="{ item }">
                <v-btn title="PDF" icon @click.stop="downloadPdfFile(item)" :disabled="isLoading">
                  <v-icon color="error">mdi-download</v-icon>
                </v-btn>
                <v-btn title="XML" icon @click.stop="downloadXmlFile(item)" :disabled="isLoading">
                  <v-icon color="primary">mdi-download</v-icon>
                </v-btn>
                <v-btn title="Cancelación" icon @click.stop="CancelBilling(item)" :disabled="isLoading" v-show="item.status === 'Creada'">
                  <v-icon color="primary">mdi-cancel</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog v-model="isConfirmationOpen" max-width="500">
      <v-card>
        <v-card-title>
          <span class="font-weight-light" style="font-size: 16pt">Alerta!!</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{ message }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" depressed small :color="buttonType" @click.stop="closeDialog">Aceptar</v-btn>
        </v-card-actions>
        <div>&nbsp;</div>
      </v-card>
    </v-dialog>
    </v-row>
    <v-dialog v-model="isCancellationDialogOpen" max-width="600" persistent>
      <cancellation :item="item" @saved="validCancellation"
        @cancel="isCancellationDialogOpen = false"/>
    </v-dialog>
    <v-dialog v-model="isSettingsDialogOpen" max-width="600" persistent>
      <settings @saved="validTiket" @cancel="isSettingsDialogOpen = false"></settings>
    </v-dialog>
  </v-container>
</template>

<script>
import { loading, showMessage, userInfo, customerInfo } from '../../mixins'
import { extractDateTime, formatDateTime } from '../../helpers/sharedFunctions'
import Settings from '../Admin/Settings'
import Cancellation from '../Admin/Cancelation'

export default {
  name: 'BillingList',
  components: {
    Settings: Settings,
    Cancellation: Cancellation
  },
  mixins: [loading, showMessage, userInfo, customerInfo],
  props: ['bus'],
  data: () => ({
    isSettingsDialogOpen: false,
    isCancellationDialogOpen: false,
    item: {},
    customer: {},
    billings: [],
    counter: 0,
    search: '',
    options: {
      itemsPerPage: 15,
      sortBy: ['Id'],
      sortDesc: [false]
    },
    isConfirmationOpen: false,
    message: '',
    buttonType: 'primary'
  }),
  computed: {
    headers () {
      return [
        { text: 'Id', value: 'id', align: 'center', sortable: true, filterable: true },
        { text: 'Opciones', value: 'options', align: 'center', width: '140px' },
        { text: 'Tiket', value: 'idTiket', align: 'center', sortable: true, filterable: true },
        { text: 'Nombre', value: 'name', align: 'center', sortable: true, filterable: true },
        { text: 'RFC Receptor', value: 'rfc', align: 'center', sortable: true, filterable: true },
        { text: 'RFC Emisor', value: 'emitter', align: 'center', sortable: true, filterable: true },
        { text: 'CreationDate', value: 'creationDate', align: 'center', sortable: true, filterable: true },
        { text: 'Total', value: 'amount', align: 'center', sortable: true, filterable: true },
        { text: 'Estatus', value: 'status', align: 'center', sortable: true, filterable: true }
      ]
    }
  },
  methods: {
    openWhatsapp () {
      this.$router.push({ name: 'Whatsapp' })
    },
    closeDialog () {
      this.isConfirmationOpen = false
    },
    openConfig () {
      this.isSettingsDialogOpen = true
    },
    closeConfig () {
      this.isSettingsDialogOpen = false
    },
    openCancellation () {
      this.isCancellationDialogOpen = true
    },
    closeCancellation () {
      this.isCancellationDialogOpen = false
      this.GetAllBillings()
    },
    downloadPdfFile (item) {
      window.open(process.env.VUE_APP_API_URL + item.pdfFile, '_blank')
    },
    downloadXmlFile (item) {
      window.open(process.env.VUE_APP_API_URL + item.xmlFile, '_blank')
    },
    GetAllBillings () {
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Billing/GetAllBillings', this.customerInfo.rfc)
        .then((r) => {
          this.billings = r.data
          this.billings = this.billings.map(l => {
            l.creationDate = formatDateTime(new Date(extractDateTime(l.creationDate)))
            return l
          })
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    },
    BillingCounter () {
      this.changeLoadingStatus(true)
      this.$proxy.post('api/Billing/BillingCounter', this.customerInfo.rfc)
        .then((r) => {
          this.counter = r.data
        })
        .catch((e) => {
          this.showHttpErrorMessage(e)
        })
        .finally(() => {
          this.changeLoadingStatus(false)
        })
    },
    validTiket () {
      this.isSettingsDialogOpen = false
    },
    CancelBilling (item) {
      this.item = item
      this.isCancellationDialogOpen = true
    },
    validCancellation () {
      this.isCancellationDialogOpen = false
    }
  },
  mounted () {
    this.GetAllBillings()
    this.BillingCounter()
  },
  created () {
    console.log(process.env.VUE_APP_API_URL)
  }
}
</script>
