class Snackbar {
  visible = false
  timeout = 5000
  x = 'right'
  y = 'bottom'
  mode = ''
  text = ''
}

export default Snackbar
