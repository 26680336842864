import Vue from 'vue'
import Vuex from 'vuex'
import snackbarModule from './snackbarModule'
import loginModule from './loginModule'
import customerModule from './customerModule'
import billingUserModule from './billingUserModule'

Vue.use(Vuex)

const state = {
  isLoading: false
}

const getters = {
  isLoading: state => state.isLoading
}

const actions = {
  changeLoadingStatus (context, isLoading) {
    context.commit('changeLoadingStatus', isLoading)
  }
}

const mutations = {
  changeLoadingStatus (state, isLoading) {
    if (typeof isLoading !== 'boolean') {
      isLoading = false
    }
    state.isLoading = isLoading
  }
}

export default new Vuex.Store({
  modules: {
    snackbarModule,
    loginModule,
    customerModule,
    billingUserModule
  },
  state,
  getters,
  actions,
  mutations
})
